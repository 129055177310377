import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../component/Header";
import notfound from '../images/notfound.jpg';

export default function Service() {    
    return (
        <div>
            <Header transparent='true'/>
            <section className='page-notfound' style={{backgroundImage:`url(${notfound})`}}>
                <div className="container">
                    <div className='page-notfound-content'>
                        <h1>404</h1>      
                        <p>Something went wrong...</p>
                        <div>
                            <Link className="btn-more white isview" to='/'>
                                <div className="btn-more-wrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                                    <circle r="38" cx="40" cy="40" stroke="#fff" strokeWidth="2" fill="none"></circle>
                                    <circle r="38" cx="40" cy="40" stroke="#fff" strokeWidth="2" fill="none"></circle>
                                    </svg>
                                    <i></i>
                                </div>        
                                <p className='right'>back home</p>
                            </Link>
                        </div>
                    </div>                    
                </div>                         
            </section>           
 
        </div>
        
    );
}