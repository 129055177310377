import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "../component/Header";
import { Row, Col, BackTop, Breadcrumb, Pagination, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// import kvimg from "../images/kv.jpg";
import kvimg from "../images/news_bg.jpg";

export default function News() {
    const { t, i18n } = useTranslation();
    const num = 5; //一頁幾條新聞
    const [total, setTotal] = useState(0); //總新聞數    
    const [current, setCurrent] = useState(1); //當前頁面
    const [newslist, setNewslist] = useState(null); //所有新聞物件
    const [min, setMin] = useState(0); //頁數的最小值
    const [max, setMax] = useState(5); //頁數的最大值
    const onChangePage = (page) =>{
        setCurrent(page);
        if(page <= 1){
            setMin(0);
            setMax(num);
        }else{
            setMin((page-1)*num);
            setMax((page-1)*num+num);
        }
        document.getElementById('pagetitle').scrollIntoView({block: 'start', behavior: 'smooth'});      
    }
    //去除字串中的html標籤並只留下50字
    const delHtmlTag = (str) => {
        if(str === undefined){
            return ''; 
        }
        str = str.replace(/<figure\b[^<]*(?:(?!<\/figure>)<[^<]*)*<\/figure>/gi,"");
        str = str.replace(/<[^>]+>/g,"");
        if(str.length > 50){
            str = str.substring(0,50);
        }       
        return str;
    }
    const loadItem = () => {   
        let arr = [1,2,3,4,5];
        return arr.map((item,index) => {
            return <div className='news-item' key={index}>
                        <Skeleton active avatar />
                    </div>
        });        
    };
    useEffect(()=>{
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API + 'newsposts?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事 
            let data = response.data.data.sort(function(a,b){
                return Date.parse(b.attributes.dt) - Date.parse(a.attributes.dt);
            })
              
            if(i18n.resolvedLanguage !== "EN"){
                let newData = data.filter(item => item.attributes.en !== "Y");
                newData = data.filter(item => item.attributes.active !== false);
                setNewslist(newData); 
                setTotal(newData.length); 
            }else{
                let newData = data.filter(item => item.attributes.active !== false);
                setNewslist(newData); 
                setTotal(newData.length); 
            }
            
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        });
    },[i18n.resolvedLanguage])
    return (
        <>
            <Header/>
            <div className='page-title-bg' style={{backgroundImage:`url(${kvimg})`}} id='pagetitle'>
                {t('news.subtitle') ? <p className='page-title-sub'>{t('news.subtitle')}</p> : ''}
                <h1>NEWS</h1>                
            </div>    
            <div className='breadcrumb'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            News
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>   
            </div>   
            <section className='page-news-section'>
                <div className='container'>
                    <div className="page-news-content">
                        <Row>
                            <Col lg={{span:16,offset:4}} md={{span:16,offset:4}} sm={24} xs={24}>
                                <div className='page-news-list'>
                                    {newslist ? newslist.slice(min,max).map(item => {
                                        if(item.attributes.external === "Y"){
                                            return  <a className='news-item' href={item.attributes.hyperlink} key={item.id} target="_blank" rel="noreferrer">
                                                        <div className='news-item-date'>
                                                            <div className='news-item-date-wrap'>
                                                                <div className='news-item-date-day'>{item.attributes.dt.split("-")[2].slice(0,2)}</div>
                                                                <div className='news-item-date-month'>{item.attributes.dt.split("-")[0]+ "." + item.attributes.dt.split("-")[1]}</div>
                                                            </div>                                                            
                                                        </div>
                                                        <div className='news-item-content'>
                                                            <h3>{item.attributes.title}</h3>
                                                            <p dangerouslySetInnerHTML={{__html:delHtmlTag(item.attributes.content)}}></p>
                                                        </div>
                                                    </a>
                                        }else{
                                            return  <Link className='news-item' to={`/news/${item.id}`} key={item.id}>
                                                        <div className='news-item-date'>
                                                            <div className='news-item-date-wrap'>
                                                                <div className='news-item-date-day'>{item.attributes.dt ? item.attributes.dt.split("-")[2].slice(0,2) : ''}</div>
                                                                <div className='news-item-date-month'>{item.attributes.dt ? item.attributes.dt.split("-")[0]+ "." + item.attributes.dt.split("-")[1] : ''}</div>
                                                            </div>
                                                        </div>
                                                        <div className='news-item-content'>
                                                            <h3>{item.attributes.title}</h3>
                                                            <p dangerouslySetInnerHTML={{__html:delHtmlTag(item.attributes.content)}}></p>
                                                        </div>
                                                    </Link>
                                        }
                                       
                                    }): loadItem()}
                                </div>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <div className="pagination">
                                    <Pagination current={current} onChange={onChangePage} defaultCurrent={1} total={total} defaultPageSize={num} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
                </div>
            </section>
            <BackTop />
        </>
        
    );
}