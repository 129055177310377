import { useState,useEffect} from 'react';

const useRWD=()=>{
    const [mobile,setMobile]=useState("mobile");

    const handleRWD=()=>{
        if(window.devicePixelRatio === 1.5 && window.innerWidth>767)
            setMobile("hisesPC");
        else if (window.innerWidth>767)
            setMobile("PC");
        else if (window.innerWidth>575)
            setMobile("tablet");
        else
            setMobile("mobile");
    }

    useEffect(()=>{    
        window.addEventListener('resize',handleRWD);
        handleRWD();         
        return(()=>{
            window.removeEventListener('resize',handleRWD);
        })
    },[]);

    return mobile;
}

export default useRWD;