import React, { useEffect, useRef } from 'react';
import { Link as Alink } from 'react-router-dom';
import Header from "../component/Header";
import { Row, Col, BackTop, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import about_kv from "../images/about_kv.jpg";
import view_img from "../images/view_img.jpg";
import spirits_mountain01 from "../images/mountain01.png";
import spirits_mountain02 from "../images/mountain02.png";
import spirits_clouds from "../images/clouds.png";
import avatar_chairman from "../images/avatar_chairman.jpg";
import avatar_coo from "../images/avatar_coo.jpg";
import avatar_cbo from "../images/avatar_cbo.jpg";
import avatar_cfo from "../images/avatar_cfo.jpg";
import avatar_cto from "../images/avatar_cto.jpg";

export default function About() {    
    const { t, i18n } = useTranslation();
    const toLink = (url) =>{
        let anchorElement = document.getElementById(url);
        anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
    const scrollref = useRef(null);
    //重新整理頁面，讓動畫重新讀取位置
    const reFreshWindow = () =>{
        window.location.reload(); 
    }

    useEffect(()=>{    
        gsap.registerPlugin(ScrollTrigger); 
        const element = scrollref.current;      
        gsap.fromTo(
            element.querySelector(".page-kv-img"),
            {        
                y: 0
            },
            {        
                y: 80,
                scrollTrigger: {
                trigger: element.querySelector(".page-title"),
                start: "top top",
                end: "bottom top",
                scrub: true,
                }
            }
        );
        gsap.fromTo(
            element.querySelector(".view-img"),
            {        
                y: 0
            },
            {        
                y: 80,
                scrollTrigger: {
                trigger: element.querySelector(".view-section"),
                start: "top top",              
                scrub: true,
                }
            }
        );        
        gsap.to(element.querySelector(".mountain02"), {
            scrollTrigger: {
                trigger: element.querySelector(".spirits-section"),
                start: "top top",               
                scrub: true,
            }, 
            y: 200,
        });
        gsap.to(element.querySelector(".clouds"), {
            scrollTrigger: {
                trigger: element.querySelector(".spirits-section"),
                start: "top top",               
                scrub: true,
            }, 
            y: 100,
        }); 
    })
    return (
        <div ref={scrollref}>
            <Header reFreshWindow={reFreshWindow}/>
            <div className='page-title'>
                {t('about.subtitle') ? <p className='page-title-sub'>{t('about.subtitle')}</p> : ''}
                <h1>ABOUT US</h1>                          
                <ul className='page-title-link'>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("company")}} >{t('menu.about-1')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("view")}} >{t('menu.about-2')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("team")}} >{t('menu.about-3')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("spirits")}} >{t('menu.about-4')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("history")}} >{t('menu.about-5')}</a></li>
                </ul>                
            </div>           
            <div className='page-kv'>
                <div className='page-kv-img-wrap'>
                    <img src={about_kv} alt={about_kv} className='page-kv-img'/>
                </div>                
            </div>
            <div className='breadcrumb'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Alink to="/">Home</Alink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            About Us
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>   
            </div>
            <section className='page-about-section' id="company">
                <div className='container'>
                    <Row>
                        <Col lg={{span:12,offset:6}} md={{span:12,offset:6}} sm={24} xs={24}>
                            <div className='company-section-txt'>
                                <p>{t('about.first-p')}</p>
                                <p>{t('about.first-p2')}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='page-about-section view-section' id="view">
                <div className='section-top-line'></div>
                <div className='container'>
                    <Row align="middle">
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <div>
                                <div className='page-section-title'>
                                    <h2>MISSION & VISION</h2>
                                    {t('about.mission-title') ? <p>{t('about.mission-title')}</p> : ''}                                     
                                </div>                                
                                <p>{t('about.mission-p1')}</p>
                                <p>{t('about.mission-p2')}</p>
                                <p>{t('about.mission-p3')}</p>
                            </div>
                        </Col>
                        <Col lg={18} md={18} sm={24} xs={24}>
                            <div className='view-img-wrap'>
                                <img className='view-img' src={view_img} alt={view_img}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='page-about-section team-section' id="team">
                <div className='section-top-line'></div>
                <div className='container'>                    
                    <div className='page-section-title'>
                        <h2>Management Team</h2>
                        {t('about.team-title') ? <p>{t('about.team-title')}</p> : ''} 
                    </div>
                    <Row gutter={[32, 32]}>
                        <Col lg={{span:6,offset:6}} md={{span:6,offset:6}} sm={24} xs={24}>
                            <div className='team-box'>
                                <div className='team-box-photo'>
                                    <img src={avatar_chairman} alt={t('about.team-chairman-name')} />
                                </div>
                                <div className='team-box-txt'>
                                    <div className='team-box-txt-title'>{t('about.team-chairman')}</div>
                                    <div className='team-box-txt-name'>{t('about.team-chairman-name')}</div>
                                    <div className={`team-box-txt-en-name ${i18n.resolvedLanguage === "EN" ? 'bold':''}`}>{t('about.team-chairman-en-name')}</div>
                                    <div className={`team-box-txt-description ${i18n.resolvedLanguage === "EN" ? 'text-center':''}`}>
                                        {t('about.team-chairman-txt')}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <div className='team-box'>
                                <div className='team-box-photo'>
                                    <img src={avatar_coo} alt={t('about.team-coo-name')} />
                                </div>
                                <div className='team-box-txt'>
                                    <div className='team-box-txt-title'>{t('about.team-coo')}</div>
                                    <div className='team-box-txt-name'>{t('about.team-coo-name')}</div>
                                    <div className={`team-box-txt-en-name ${i18n.resolvedLanguage === "EN" ? 'bold':''}`}>{i18n.resolvedLanguage === "EN" ? "("+ t('about.team-coo-en-name') +")" : t('about.team-coo-en-name')}</div>
                                    <div className={`team-box-txt-description ${i18n.resolvedLanguage === "EN" ? 'text-center':''}`}>
                                        {t('about.team-coo-txt')}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[32, 32]}>
                        <Col lg={{span:6,offset:3}} md={{span:6,offset:3}} sm={24} xs={24}>
                            <div className='team-box'>
                                <div className='team-box-photo'>
                                    <img src={avatar_cfo} alt={t('about.team-cfo-name')} />
                                </div>
                                <div className='team-box-txt'>
                                    <div className='team-box-txt-title'>{t('about.team-cfo')}</div>
                                    <div className='team-box-txt-name'>{t('about.team-cfo-name')}</div>
                                    <div className={`team-box-txt-en-name ${i18n.resolvedLanguage === "EN" ? 'bold':''}`}>{i18n.resolvedLanguage === "EN" ? "("+ t('about.team-cfo-en-name') +")" : t('about.team-cfo-en-name')}</div>
                                    <div className={`team-box-txt-description ${i18n.resolvedLanguage === "EN" ? 'text-center':''}`}>
                                        {t('about.team-cfo-txt')}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <div className='team-box'>
                                <div className='team-box-photo'>
                                    <img src={avatar_cbo} alt={t('about.team-cbo-name')} />
                                </div>
                                <div className='team-box-txt'>
                                    <div className='team-box-txt-title'>{t('about.team-cbo')}</div>
                                    <div className='team-box-txt-name'>{t('about.team-cbo-name')}</div>
                                    <div className={`team-box-txt-en-name ${i18n.resolvedLanguage === "EN" ? 'bold':''}`}>{i18n.resolvedLanguage === "EN" ? "("+ t('about.team-cbo-en-name') +")" : t('about.team-cbo-en-name')}</div>
                                    <div className={`team-box-txt-description ${i18n.resolvedLanguage === "EN" ? 'text-center':''}`}>
                                        {t('about.team-cbo-txt')}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={24} xs={24}>
                            <div className='team-box'>
                                <div className='team-box-photo'>
                                    <img src={avatar_cto} alt={t('about.team-cto-name')} />
                                </div>
                                <div className='team-box-txt'>
                                    <div className='team-box-txt-title'>{t('about.team-cto')}</div>
                                    <div className='team-box-txt-name'>{t('about.team-cto-name')}</div>
                                    <div className={`team-box-txt-en-name ${i18n.resolvedLanguage === "EN" ? 'bold':''}`}>{i18n.resolvedLanguage === "EN" ? "("+ t('about.team-cto-en-name') +")" : t('about.team-cto-en-name')}</div>
                                    <div className={`team-box-txt-description ${i18n.resolvedLanguage === "EN" ? 'text-center':''}`}>
                                        {t('about.team-cto-txt')}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='spirits-section page-about-section' id="spirits"> 
                <div className='page-section-title'>
                    <h2>Spirits/Motivation </h2>
                    {t('about.motivation-tilte') ? <p>{t('about.motivation-tilte')}</p> : ''}                     
                </div>  
                <p className='spirits-p'>{t('about.motivation-p1')}<br/>{t('about.motivation-p2')}<br/>{t('about.motivation-p3')}<br/>{t('about.motivation-p4')}</p>
                <div className="spirits-photo">                    
                    <img className='mountain02' src={spirits_mountain02} alt={spirits_mountain02} />
                    <img className='clouds' src={spirits_clouds} alt={spirits_clouds} />
                    <img className='mountain01' src={spirits_mountain01} alt={spirits_mountain01} />                    
                </div>
            </section>
            <section className='page-about-section history-section' id="history">
                <div className="container">
                    <div className='page-section-title'>
                        <h2>History and Milestones of Micro Electricity</h2>
                        {t('about.milestone-title') ? <p>{t('about.milestone-title')}</p> : ''}            
                    </div> 
                    <div className='history-all'>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2024</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2024-1')}<br/>
                                        {t('about.milestone-2024-2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2023</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2023-1')}<br/>
                                        {t('about.milestone-2023-2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2022</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2022-1')}<br/>
                                        {t('about.milestone-2022-2')}<br/>
                                        {t('about.milestone-2022-3')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2021</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2021-1')}<br/>
                                        {t('about.milestone-2021-2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2020</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2020-1')}<br/>
                                        {t('about.milestone-2020-2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2019</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2019-1')}<br/>
                                        {t('about.milestone-2019-2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2018</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2018-1')}<br/>
                                        {t('about.milestone-2018-2')}<br/>
                                        {t('about.milestone-2018-3')}<br/>
                                        {t('about.milestone-2018-4')}<br/>
                                        {t('about.milestone-2018-5')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2017</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2017-1')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2016</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2016')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='history-box'>
                            <div className='history-box-item'>
                                <div className='year'>2015</div>
                                <div className="line"><span></span></div>
                                <div className='description'>
                                    <p>
                                        {t('about.milestone-2015')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
   
                </div>
            </section>
            <BackTop />            
        </div>
        
    );
}