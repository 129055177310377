import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../component/Header";
import { Row, Col, BackTop, Breadcrumb, Input, Form, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import kvimg from "../images/kv.jpg";

export default function Contact() {
    const { t } = useTranslation();
    const { TextArea } = Input;  
    const [form] = Form.useForm();

    const onFinish = (values) => {
        let data = {
            data: {
              ...values
            }
        }
        axios.post(process.env.REACT_APP_API + 'contacts', data,{
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事 
            console.log(response);
            message.success('表單已成功送出!'); 
            form.resetFields();
        }).catch(function (error) { // 失敗後要做的事
            message.error('連線異常，請稍後再試一次!'); 
            console.log(error);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };   
    const company = [
        {
            id:1,
            company:t('contact.hsinchu-company'),
            address:t('contact.hsinchu-address'),
            tel:t('contact.hsinchu-tel'),
            fax:t('contact.hsinchu-fax'),
            email:t('contact.hsinchu-mail')
        },
        {
            id:2,
            company:t('contact.hsinchu2-company'),
            address:t('contact.hsinchu2-address'),
            tel:t('contact.hsinchu2-tel'),
            fax:t('contact.hsinchu2-fax'),
            email:t('contact.hsinchu2-mail')
        },
        {
            id:3,
            company:t('contact.taipei-company'),
            address:t('contact.taipei-address'),
            tel:t('contact.taipei-tel'),
            fax:t('contact.taipei-fax'),
            email:t('contact.taipei-mail')
        },
        {
            id:4,
            company:t('contact.tainan-company'),
            address:t('contact.tainan-address'),
            tel:t('contact.tainan-tel'),
            fax:t('contact.tainan-fax'),
            email:t('contact.tainan-mail'),
            tel_s:t('contact.tainan-tel-s'),
        },
        {
            id:5,
            company:t('contact.pingtung-company'),
            address:t('contact.pingtung-address'),
            tel:t('contact.pingtung-tel'),
            fax:t('contact.pingtung-fax'),
            email:t('contact.pingtung-mail')
        },
    ]
    return (
        <div>
            <Header transparent="true"/>
            <div className='page-title-bg2' style={{backgroundImage:`url(${kvimg})`}}>
                {t('contact.subtitle') ? <p className='page-title-sub'>{t('contact.subtitle')}</p> : ''}
                <h1>CONTACT US</h1>                
            </div>  
            <div className='breadcrumb'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Contact Us
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>   
            </div>          
            <section className='page-contact'>
                <div className='container'>
                    <div className='address'>
                        <Row gutter={[48, 32]} align='center'>                      
                            { company.map((item)=>{
                                return (
                                    <Col lg={4} md={8} sm={12} xs={24} key={item.id}>
                                        <div className='address-item'>
                                            <h3>{item.company}</h3>                                            
                                            {item.address ? <p className='p-address'><span>Address:</span> {item.address}</p> : ""}
                                            {item.tel ? <p><span>{item.id === 4 ? item.tel_s : 'Tel:'}</span> {item.tel}</p> : ""}
                                            {item.fax ? <p><span>Fax:</span> {item.fax}</p> : ""}
                                            {item.email ? <p><span>Email:</span> {item.email}</p> : ""}
                                        </div>
                                    </Col>
                                )
                            }) }
                        </Row>
                    </div>
                </div>
            </section>
            <section className='page-contact-form' id='contactform'>
                <div className='container'>
                    
                    <Row>
                        <Col lg={{span:12,offset:6}} md={{span:12,offset:6}} sm={24} xs={24}>
                            <div className='page-contact-box'>
                                <h3>{t('contact.form-title')}</h3>
                                <div className='page-contact-formbox'>
                                <Form
                                    form={form}
                                    name="basic"
                                    layout="vertical"
                                    labelCol={{
                                        span: 24,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                        <Form.Item label={t('contact.name')} name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label={t('contact.mail')} name="mail" rules={[{ type: 'email', required: true, message: 'Email is not a valid email!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label={t('contact.phone')} name="phone" rules={[{ required: true, message: 'Please input your phone!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label={t('contact.message')} name="message" rules={[{ required: true, message: 'Please input your message!' }]}>
                                            <TextArea rows={8} maxLength={9999} />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary-dark" htmlType="submit" block>{t('contact.form-btn')}</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <BackTop />
        </div>
        
    );
}