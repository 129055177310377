import React, { useState, useRef, useEffect } from 'react';
import Header from "../component/Header";
import { Link } from "react-router-dom";
import { Row, Col, BackTop, Skeleton } from 'antd';
import { gsap } from "gsap";
import axios from 'axios';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';
import kvimg from "../images/kv.jpg";
import aboutbg from "../images/about_bg.jpg";
import servicebg from "../images/service_bg.jpg";
import icon_solar from "../images/icon-solar.svg";
import icon_solar_yellow from "../images/icon-solar-yellow.svg";
import icon_water_yellow from "../images/icon-water-yellow.svg";
import icon_assets_yellow from "../images/icon-assets-yellow.svg";
import icon_leaves from "../images/icon-leaves.svg";
import icon_plug from "../images/icon-plug.svg";
import icon_light from "../images/icon-light.svg";
import icon_circle from "../images/icon-circle.svg";
import icon_sale from "../images/icon-sale.svg";
import advantage_1 from "../images/advantage_1.jpg";
import advantage_2 from "../images/advantage_2.jpg";
import advantage_3 from "../images/advantage_3.jpg";

export default function Home () {
  const { t, i18n } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const scrollref = useRef(null);
  const [btnClass, setBtnClass] = useState("");
  const [newslist,setNewslist] = useState(null);
  //新聞列表預覽
  const loadItem = () => {   
    let arr = [1,2,3];
    return arr.map((item,index) => {
        return <div className='news-item' key={index}>
                    <Skeleton active avatar />
               </div>
    });        
  };

  //跳到連結處
  const toLink = (url) =>{
    let anchorElement = document.getElementById(url);
    anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  //重新整理頁面，讓動畫重新讀取位置
  const reFreshWindow = () =>{
    window.location.reload(); 
  }

  //去除字串中的html標籤並只留下50字
  const delHtmlTag = (str) => {
    if(str === undefined){
        return ''; 
    }
    str = str.replace(/<figure\b[^<]*(?:(?!<\/figure>)<[^<]*)*<\/figure>/gi,"");
    str = str.replace(/<[^>]+>/g,"");    
    // str = str.replace(/&nbsp;/ig, "");
    if(str.length > 65){
        str = str.substring(0,65);
    }       
    return str;
  }

  useEffect(()=>{      
    //讀取新聞
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API + 'newsposts?pagination[page]=1&pagination[pageSize]=999',
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
      }
    }).then(function (response) { // 成功後要做的事 
        let data = response.data.data.sort(function(a,b){
          return Date.parse(b.attributes.dt) - Date.parse(a.attributes.dt);
        })
        if(i18n.resolvedLanguage !== "EN"){
          let newData = data.filter(item => item.attributes.en !== "Y");
          newData = data.filter(item => item.attributes.active !== false);
          setNewslist(newData.slice(0,3)); 
        }else{
          let newData = data.filter(item => item.attributes.active !== false);
          setNewslist(newData.slice(0,3)); 
        }
    }).catch(function (error) { // 失敗後要做的事
        console.log(error);
    });
    
    //動畫
    setBtnClass("isview");
    const element = scrollref.current;
    gsap.from(element.querySelectorAll(".kv-title-word"),
    {
      y: 300, 
      duration: 1,
      stagger: 0.05
    });
    if(element.querySelector(".kv-subtitle")){
      gsap.from(element.querySelector(".kv-subtitle"),
      {
        y: 300, 
        duration: 1.5,
        delay: 0.5,
      });
    }   
    gsap.fromTo(
      element.querySelector(".kv-img"),
      {        
        y: 0
      },
      {        
        y: 80,
        scrollTrigger: {
          trigger: element.querySelector(".kv-img"),
          start: "top top",
          end: "bottom center",
          scrub: true,
        }
      }
    );
    gsap.fromTo(
      element.querySelector(".about-bg"),
      {        
        y: 0
      },
      {        
        y: 80,
        scrollTrigger: {
          trigger: element.querySelector(".about-section"),
          start: "top top",
          end: "bottom center",
          scrub: true,
        }
      }
    );
    gsap.fromTo(
      element.querySelector(".about-section-title"),
      { 
        y: 300,
      },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: element.querySelector(".about-section"),
          start: "top bottom",
          toggleClass: {targets: element.querySelector(".btn-more2"), className: "isview"},
        }
      }
    );       
    gsap.fromTo(
      element.querySelector(".news-section-title"),
      { y: 300 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: element.querySelector(".news-section"),
          start: "top bottom",
          toggleClass: {targets: element.querySelector(".btn-more3"), className: "isview"},
        }
      }
    );    
    gsap.fromTo(
      element.querySelector(".service-bg"),
      {        
        y: 0
      },
      {        
        y: 120,
        scrollTrigger: {
          trigger: element.querySelector(".service-section"),
          start: "top top",
          scrub: true,
        }
      }
    );
    gsap.fromTo(
      element.querySelector(".advantage-section-title"),
      { y: 300 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: element.querySelector(".advantage-section"),
          start: "top 80%",         
        }
      }
    );
    gsap.fromTo(
      element.querySelector(".service-section-title"),
      { y: 300 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: element.querySelector(".service-section"),
          start: "top bottom",
          end: "bottom 50%",
          toggleClass: {targets: element.querySelector(".btn-more4"), className: "isview"},
        }
      }
    );
    ScrollTrigger.matchMedia({
      '(min-width: 767px)': () => {
        ScrollTrigger.create({
          trigger: element.querySelector(".service-section-move"),
          start: "top 0%+=160px",
          endTrigger: element.querySelector(".service-container"),
          end: "bottom 55%",
          pin: true,
          scrub: true,
        });
      },
    }) 
  },[i18n.resolvedLanguage])
  return (
  <div ref={scrollref}>   
    <Header transparent='true' reFreshWindow={reFreshWindow}/>
    <section className="kv">
      <img src={kvimg} className="kv-img" alt="kv"/>
      <div className='kv-title-wrap'>      
        <h1 className="kv-title" translate='no'>
          <span className='kv-title-word'>S</span>
          <span className='kv-title-word'>O</span>
          <span className='kv-title-word'>L</span>
          <span className='kv-title-word'>A</span>
          <span className='kv-title-word'>R</span>
          <br/>          
          <span className='kv-title-word'>AS</span>          
          <span className='kv-title-word'>A</span>      
          <span className='kv-title-word'>S</span>
          <span className='kv-title-word'>E</span>
          <span className='kv-title-word'>R</span>
          <span className='kv-title-word'>V</span>
          <span className='kv-title-word'>I</span>
          <span className='kv-title-word'>C</span>
          <span className='kv-title-word'>E</span>
        </h1>
        {t("home.subtitle") ? <h2 className="kv-subtitle">{t("home.subtitle")}</h2> : '' } 
      </div>
      <a className={`btn-more secondary ${btnClass}`} href='/#' onClick={(e)=> {e.preventDefault();toLink("about");}}>
        <div className='btn-more-wrap'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
            <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
            <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
          </svg>
          <i className='arrow-right'></i>
        </div>        
        <p className='bottom'>Scroll down</p>
      </a>
    </section>   
    <section className='about-section' id="about">
      <div className='container'>
        <Row>
          <Col lg={6} md={6} sm={24} xs={24}>
            <div className='section-title-wrap'>
              <div className='about-section-title'>
                <h2 className='section-title'>About Us</h2>
                {t("home.about-main-title") ? <span>{t("home.about-main-title")}</span> : '' } 
              </div>              
            </div>
            <Link className="btn-more btn-more2" to='/about'>
              <div className="btn-more-wrap">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                  <circle r="38" cx="40" cy="40" stroke="#0d0d0d" strokeWidth="2" fill="none"></circle>
                  <circle r="38" cx="40" cy="40" stroke="#0d0d0d" strokeWidth="2" fill="none"></circle>
                </svg>
                <i></i>
              </div>        
              <p className='right'>See About Us</p>
            </Link>
          </Col>
          <Col lg={{span:12,offset:6}} md={{span:12,offset:6}} sm={24} xs={24}>
            <div className='first-section-p'>
              <p>{t('home.aboutp')}</p>
              <p>{t('home.aboutp2')}</p>
            </div>
            <div className='showNum-wrap'>
              <Row>
                <Col lg={8} md={8} sm={8} xs={8}>                  
                    <div className='showNum'>
                      <div className='showNum-icon'>                        
                        <img src={icon_solar_yellow} alt={icon_solar_yellow} />
                      </div>                    
                      <div className='showNum-title'><span>+720</span> MW</div>
                      <div className='showNum-p'>{t('home.icon-title1')}</div>
                    </div>                  
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>                  
                    <div className='showNum'>
                      <div className='showNum-icon'>
                        <img src={icon_water_yellow} alt={icon_water_yellow} />
                      </div>                    
                      <div className='showNum-title'><span>+160</span> MW</div>
                      <div className='showNum-p'>{t('home.icon-title2')}</div>
                    </div>                  
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>                  
                    <div className='showNum'>
                      <div className='showNum-icon'>
                        <img src={icon_assets_yellow} alt={icon_assets_yellow} />
                      </div>                    
                      <div className='showNum-title'><span>+300</span> MW</div>
                      <div className='showNum-p'>{t('home.icon-title3')}</div>
                    </div>                  
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <img className="about-bg" src={aboutbg} alt="aboutbg"/>
    </section>
    <section className='news-section'>
      <div className='section-top-line'></div>
      <div className='container'>        
        <Row>
          <Col lg={6} md={6} sm={24} xs={24}>
            <div className='section-title-wrap'>
              <div className='news-section-title'>
                <h2 className='section-title'>News</h2>
                {t("home.news-main-title") ? <span>{t("home.news-main-title")}</span> : '' }  
              </div>               
            </div>  
            <Link className="btn-more btn-more3" to='/news'>
              <div className="btn-more-wrap">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                  <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                  <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                </svg>
                <i></i>
              </div>        
              <p className='right'>See News</p>
            </Link>
          </Col>
          <Col lg={{span:12,offset:6}} md={{span:12,offset:6}} sm={24} xs={24}>
            {newslist ? newslist.map(item =>{
              if(item.attributes.external === "Y"){
                return (
                  <Link className='news-item' to={item.attributes.hyperlink} key={item.id}>
                    <div className='news-item-date'>
                      <div className='news-item-date-wrap'>
                        <div className='news-item-date-day'>{item.attributes.dt.split("-")[2].slice(0,2)}</div>
                        <div className='news-item-date-month'>{item.attributes.dt.split("-")[0]+ "." + item.attributes.dt.split("-")[1]}</div>
                      </div>
                    </div>
                    <div className='news-item-content with-photo'>
                        <h3>{item.attributes.title}</h3>
                        <p>{delHtmlTag(item.attributes.content)}</p>
                    </div>
                    <div className='news-item-photo'>
                      {
                        item.attributes.imageName ? <img src={`https://strapi.rfdme.io/uploads/${item.attributes.imageName}`} alt={item.attributes.imageName} /> : <Skeleton.Image active />
                      }                      
                    </div>
                  </Link>  
                );
              }else{
                return (
                  <Link className='news-item' to={`/news/${item.id}`} key={item.id}>
                    <div className='news-item-date'>
                      <div className='news-item-date-wrap'>
                        <div className='news-item-date-day'>{item.attributes.dt.split("-")[2].slice(0,2)}</div>
                        <div className='news-item-date-month'>{item.attributes.dt.split("-")[0]+ "." + item.attributes.dt.split("-")[1]}</div>
                      </div>
                    </div>
                    <div className='news-item-content with-photo'>
                        <h3>{item.attributes.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: delHtmlTag(item.attributes.content)}}></p>
                    </div>
                    <div className='news-item-photo'>
                      {
                        item.attributes.imageName ? <img src={`https://strapi.rfdme.io/uploads/${item.attributes.imageName}`} alt={item.attributes.imageName} /> : <Skeleton.Image active />
                      } 
                    </div>
                  </Link>  
                );
              }
              
            }) : loadItem()}       
          </Col>
        </Row>
      </div>
    </section>
    <section className={`service-section ${i18n.resolvedLanguage === "EN" ? "service-section-en" : ''}`}>
      <div className='container service-container'>
        <Row>
          <Col lg={6} md={6} sm={24} xs={24}>            
            <div className='service-section-move'>
              <div className='section-title-wrap'>
                <div className='service-section-title'>
                  <h2 className='section-title'>Solutions</h2>
                  {t("home.service-main-title") ? <span>{t("home.service-main-title")}</span> : '' }                  
                </div>              
              </div>                       
              <Link className="btn-more white btn-more4" to='/solutions'>
                <div className="btn-more-wrap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                    <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                    <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                  </svg>
                  <i></i>
                </div>        
                <p className='right'>See Our Solutions</p>
              </Link>
            </div>
          </Col>
          <Col lg={{span:12,offset:6}} md={{span:12,offset:6}} sm={24} xs={24}>
            <Row gutter={[40, 40]}>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className='service-item'>
                  <div className="service-icon">
                    <div className='icon-wrap'><img src={icon_solar} alt={icon_solar} /></div>
                  </div>
                  <h5>{t('home.service-title1')}</h5>
                  <p>{t('home.service-p1')}</p>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className='service-item'>
                  <div className="service-icon">
                    <div className='icon-wrap'><img src={icon_leaves} alt={icon_leaves} /></div>
                  </div>
                  <h5>{t('home.service-title2')}</h5>
                  <p>{t('home.service-p2')}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[40, 40]}>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className='service-item'>
                  <div className="service-icon">
                    <div className='icon-wrap'><img src={icon_circle} alt={icon_circle} /></div>
                  </div>
                  <h5>{t('home.service-title3')}</h5>
                  <p>{t('home.service-p3')}</p>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className='service-item'>
                  <div className="service-icon">
                    <div className='icon-wrap'><img src={icon_plug} alt={icon_plug} /></div>
                  </div>
                  <h5>{t('home.service-title4')}</h5>
                  <p>{t('home.service-p4')}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[40, 40]}>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className='service-item'>
                  <div className="service-icon">
                    <div className='icon-wrap'><img src={icon_light} alt={icon_light} /></div>
                  </div>
                  <h5>{t('home.service-title5')}</h5>
                  <p>{t('home.service-p5')}</p>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24}>
                <div className='service-item'>
                  <div className="service-icon">
                    <div className='icon-wrap'><img src={icon_sale} alt={icon_sale} /></div>
                  </div>
                  <h5>{t('home.service-title6')}</h5>
                  <p>{t('home.service-p6')}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <img className="service-bg" src={servicebg} alt="servicebg"/>
    </section>
    <section className='advantage-section'>
      <div className='container'>        
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className='section-title-wrap'>
              <div className='advantage-section-title'>
                <h2 className='section-title'>Our Advantages</h2>
                {t("home.advantage-main-title") ? <span>{t("home.advantage-main-title")}</span> : '' }
              </div>                   
            </div>            
          </Col>
        </Row>
        <Row align="stretch">
          <Col lg={24} md={24} sm={24} xs={24}>
              <Row gutter={32}>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <div className='box box-flex-space-between'>
                      <div className='box-content'>
                        <h3>{t('home.advantage-title1')}</h3>
                        <p>{t('home.advantage-p1')}</p>
                      </div>
                      <div className='box-photo'>
                        <img src={advantage_1} alt={t('home.advantage-title1')} className='advantage-1'/>
                      </div>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <div className='box box-flex-space-between'>
                      <div className='box-photo'>
                        <img src={advantage_2} alt={t('home.advantage-title2')} className='advantage-2'/>
                      </div>
                      <div className='box-content'>
                        <h3>{t('home.advantage-title2')}</h3>
                        <p>{t('home.advantage-p2')}</p>
                      </div>
                      
                  </div>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <div className='box box-flex-space-between'>
                      <div className='box-content'>
                        <h3>{t('home.advantage-title3')}</h3>
                        <p>{t('home.advantage-p3')}</p>
                      </div>
                      <div className='box-photo'>
                        <img src={advantage_3} alt={t('home.advantage-title3')} className='advantage-3'/>
                      </div>
                  </div>
                </Col>
              </Row>            
          </Col>
        </Row>

      </div>
    </section>
    <BackTop />
  </div>
  )  
};
