import React, { useEffect, useState } from 'react';
import useRWD from '../hook/useRWD';
import { Link } from 'react-router-dom';
import Header from "../component/Header";
import { BackTop, Breadcrumb, Image, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import mapMarker from "../images/map-marker.svg";
import mapMarkerSelect from "../images/map-marker-select.svg";
import axios from 'axios';

const cityOrder = [
    '宜蘭縣',
    '基隆巿',
    '臺北巿',
    '新北巿',
    '桃園縣',
    '新竹縣',
    '新竹巿',
    '苗栗縣',
    '台中市',
    '彰化縣',
    '南投縣',
    '雲林縣',
    '嘉義縣',
    '嘉義巿',
    '台南市',
    '高雄市',
    '屏東縣',
    '臺東縣',
    '花蓮縣',
    '澎湖縣',
    '金門縣',
    '連江縣',
]; 

const containerStyle = {
    width: '100%',
    height: '100%'
};  
const center_hisesPC = {
    lat: 23.736359,
    lng: 122.5
}
const center_PC = {
    lat: 23.59359,
    lng: 122.6
};
const center_tablet = {
    lat: 23.56359,
    lng: 121.9
};
const center_mobile = {
    lat: 22.2,
    lng: 121.1
};
const defaultMapOptions = {
    fullscreenControl: false,
    mapId: "563988f8a27057bf",
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    panControl: true,
    gestureHandling: "none",
    maxZoom: 8,
}; 

const translationLocation = (title) => {
    if(title === "基隆市"){
        return "Keelung City";
    }
    if(title === "新北市"){
        return "New Taipei City";
    }
    if(title === "台北市" || title === "臺北市"){
        return "Taipei City";
    }
    if(title === "宜蘭市"){
        return "Yilan City";
    }
    if(title === "宜蘭縣"){
        return "Yilan County";
    }
    if(title === "桃園市"){
        return "Taoyuan City"                     
    }
    if(title === "桃園縣"){
        return "Taoyuan County"                     
    }
    if(title === "新竹市"){
        return "Hsinchu City"                     
    }
    if(title === "新竹縣"){
        return "Hsinchu County"                     
    }
    if(title === "苗栗縣"){
        return "Miaoli County"                  
    }
    if(title === "台中市" || title === "臺中市"){
        return "Taichung City"      
    }
    if(title === "彰化市"){
        return "Changhua City"                   
    }
    if(title === "彰化縣"){
        return "Changhua County"                   
    }
    if(title === "南投市"){
        return "Nantou City"                    
    }
    if(title === "南投縣"){
        return "Nantou County"                    
    }
    if(title === "雲林縣"){
        return "Yunlin County"                    
    }
    if(title === "嘉義市"){
        return "Chiayi City"                    
    }
    if(title === "嘉義縣"){
        return "Chiayi County"                    
    }
    if(title === "台南市"){
        return "Tainan City"                    
    }
    if(title === "高雄市"){
        return "Kaohsiung City"                   
    }
    if(title === "屏東市"){
        return "Pingtung City"                    
    }
    if(title === "屏東縣"){
        return "Pingtung County"                    
    }
    if(title === "台東市" || title === "臺東市"){
        return "Taitung City"                    
    }
    if(title === "台東縣" || title === "臺東縣"){
        return "Taitung County"                    
    }
    if(title === "花蓮市"){
        return "Hualien City"                    
    }
    if(title === "花蓮縣"){
        return "Hualien County"                    
    }
    if(title === "澎湖縣"){
        return "Penghu County"                    
    }
    if(title === "綠島"){
        return "Green Island"                    
    }
    if(title === "蘭嶼"){
        return "Orchid Island"                    
    }
    if(title === "金門縣"){
        return "Kinmen County"                    
    }
    if(title === "馬祖"){
        return "Matsu"                    
    }
    if(title === "連江縣"){
        return "Lienchiang County"                    
    }
}

export default function Project() {    
    const { t, i18n } = useTranslation();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDW2CnZdJRb0GvkdFy9kSYNtjNLkZTwHMU',
        language: i18n.resolvedLanguage === "EN" ? 'en-US' : 'zh-TW',
    });

    const device=useRWD();
    const [dataAjax,setDataAjax] = useState([]); 
    const [pvtitle, setPvtitle] = useState(""); 
    const [pvimgarry, setPvimgarry] = useState("");  
    //重新整理頁面，google map讀取語言
    const reFreshWindow = () =>{
        window.location.reload(); 
    }
    useEffect(()=>{     
        axios({
            method: 'GET',  
            url: process.env.REACT_APP_API + 'projects?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事
            response.data.data.forEach(item => {
                if(item.attributes.location === '臺中巿'){
                    item.attributes.location = '台中市';
                }else if(item.attributes.location === '臺南巿'){
                    item.attributes.location = '台南市';
                }else if(item.attributes.location === '嘉義巿'){
                    item.attributes.location = '嘉義縣';
                }else if(item.attributes.location === '桃園市'){
                    item.attributes.location = '桃園縣';
                }else if(item.attributes.location === '高雄巿'){
                    item.attributes.location = '高雄市';
                }
            })
            setDataAjax(response.data.data);          
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        })
    },[]);

    const onMapLoad = (map) => {   
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API + 'projects?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事          
            response.data.data.forEach(item => {
                if(item.attributes.location === '臺中巿'){
                    item.attributes.location = '台中市';
                }else if(item.attributes.location === '臺南巿'){
                    item.attributes.location = '台南市';
                }else if(item.attributes.location === '嘉義巿'){
                    item.attributes.location = '嘉義縣';
                }else if(item.attributes.location === '桃園市'){
                    item.attributes.location = '桃園縣';
                }else if(item.attributes.location === '高雄巿'){
                    item.attributes.location = '高雄市';
                }
            })
            const allArea = [];                      
            cityOrder.forEach( area =>{
                let filterArea = response.data.data.filter( item => {   
                    return item.attributes.location === area;                    
                })
                allArea.push(filterArea);
            }); 
            const filterArea = allArea.filter( item => {   
                return item.length !== 0;                    
            })            
            setPvtitle(filterArea[0][0].attributes.location);    
            setPvimgarry(filterArea[0]);    
            map.data.loadGeoJson('twGeo.json');
            map.data.setStyle({
                strokeWeight: 1,
                strokeOpacity: 0,
                strokeColor: 'transparent',
                fillColor: 'transparent',
                fillOpacity: .3
            });
            
            map.data.addListener('click', function(event) {     
                allArea.forEach((item)=>{                   
                    if(item.length > 0){
                        const countyName = event.feature.getProperty('COUNTYNAME');
                        if(item[0] && item[0].attributes.location === countyName){                               
                            map.data.revertStyle();
                            map.data.overrideStyle(event.feature, {fillColor: '#fddf9a'});
                            setPvtitle(countyName);  
                            setPvimgarry(item);                        
                        }
                    }  
                }) 
            });       
    
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        })    
        
    }
    
    const loadMap = ()=>{
        return (
            <GoogleMap
                mapContainerStyle={containerStyle}                                             
                options={defaultMapOptions}
                center={device === 'hisesPC' ? center_hisesPC : device === 'PC' ? center_PC : device === 'tablet' ? center_tablet : center_mobile}
                zoom={device === 'hisesPC' ? 8 : device === 'PC' ? 8 : device === 'tablet' ? 8 : 6.7}
                onLoad={onMapLoad}
            >
               {dataAjax.map( item => {    
                    if(item.attributes.location === pvtitle){
                        return <Marker position={{lat:parseFloat(item.attributes.latt), lng:parseFloat(item.attributes.longt)}} icon={mapMarkerSelect} key={item.attributes.pno} clickable={false}/>
                    }else{
                        return <Marker position={{lat:parseFloat(item.attributes.latt), lng:parseFloat(item.attributes.longt)}} icon={mapMarker} key={item.attributes.pno} clickable={false}/>
                    }                     
                                                
                })}
                <></>
            </GoogleMap>
        )
    }

    return (
        <div>
            <Header reFreshWindow={reFreshWindow}/>            
            <div className='page-title'>
                {t('project.subtitle') ? <p className='page-title-sub'>{t('project.subtitle')}</p> : ''}
                <h1>PROJECT</h1>
            </div>           
            <div className='breadcrumb'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Project
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>   
            </div>
            <div className='project-map'>   
                { isLoaded ? loadMap() : <div>loading...</div> }
                <div className='project-map-wrap'>
                    <div className='project-map-count'>
                        {t('project.total-count')}: <span>240</span>
                    </div>
                    <div className='project-map-panel'>
                        <div className='project-map-panel-header'>
                            <h2>{
                                 i18n.resolvedLanguage === "EN" && pvtitle ? translationLocation(pvtitle) : pvtitle ? pvtitle : <div className='loading-skeleton'></div>
                            }</h2>
                            <div className='project-map-area-count'><span>{pvimgarry.length !== 0 ? pvimgarry.length : 0} {t('project.area-count')}{i18n.resolvedLanguage === "EN" && pvimgarry.length !== 0 && pvimgarry.length > 1 ? 's':''}</span></div>
                        </div>
                        <div className='project-map-panel-content'>
                            <div className="project-map-panel-scroller">
                                {pvimgarry.length !== 0 ? pvimgarry.map(item =>{
                                    return <div className='project-photos-wrap big' key={item.attributes.pno}>
                                                <Image width={'100%'} src={`https://strapi.rfdme.io/uploads/${item.attributes.img}`} alt={'project'} />
                                                <div className='project-photos-mask'></div>
                                                <div className='project-photos-text'>
                                                    <p>{item.attributes.name}</p>
                                                </div>  
                                            </div>
                                }): <Skeleton active />}     
                            </div>                                                  
                        </div>
                    </div> 
                </div>
            </div>
            <div className="project-photos">
                <div className='project-photos-container'>
                    {dataAjax ? dataAjax.map(item=>{
                       return   <div className='project-photos-wrap' key={item.attributes.pno}>
                                    <Image width={'100%'} src={`https://strapi.rfdme.io/uploads/${item.attributes.img}`} alt={'project'} />
                                    <div className='project-photos-mask'></div>
                                    <div className='project-photos-text'>
                                        <h4>{i18n.resolvedLanguage === "EN" ? translationLocation(item.attributes.location) : item.attributes.location}</h4>
                                    </div>  
                                </div> 
                    }) : <Skeleton.Image active /> }

                </div>
            </div>  
            <BackTop />
        </div>
        
    );
}
