import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tw from './i18n/zh-TW.json';
import en from './i18n/en.json';

const resources = {
    'zh-TW': {
      translation: tw,
    },
    'EN': {
      translation: en,
    },
};
//取cookies函式        
function getCookie(name){
  let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) return arr[2]; 
  return null;
}
const nowLang = getCookie('lang') || 'zh-TW';
i18n.use(initReactI18next).init({
    resources,
    lng: nowLang,             //預設語言
    fallbackLng: 'zh-TW',     //如果當前切換的語言沒有對應的翻譯則使用這個語言，
    interpolation: {
      escapeValue: false,
    },
});
  
export default i18n;

