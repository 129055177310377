import React, { useState, useLayoutEffect, useEffect } from 'react';
import classNames from 'classnames';
import {NavLink, Link} from 'react-router-dom';
import Dropdown from './Dropdown';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import logo from "../images/logo.svg";
import logo_w from "../images/logo_white.svg";

const Header =  React.forwardRef((props,ref) => {  
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [dropclass,setDropclass]=useState("");
    const [scroll,setScroll]=useState("");
    const [menuVisible, setMenuVisible] = useState(false);
    const headerClassName = classNames({
        clearfix: true,
        headerTranparent: props.transparent,
    });
    const menuDropItem_about = [
        {
            id:t('menu.about-1'),
            title:t('menu.about-1'),
            url:"/about",
            hash:"",
        },
        {
            id:t('menu.about-2'),
            title:t('menu.about-2'),
            url:"/about",
            hash:"view",
        },
        {
            id:t('menu.about-3'),
            title:t('menu.about-3'),
            url:"/about",
            hash:"team",
        },
        {
            id:t('menu.about-4'),
            title:t('menu.about-4'),
            url:"/about",
            hash:"spirits",
        },
        {
            id:t('menu.about-5'),
            title:t('menu.about-5'),
            url:"/about",
            hash:"history",
        },
    ];
    const menuDropItem_solutions = [
        {
            id:t('menu.service-1'),
            title:t('menu.service-1'),
            url:"/solutions",
            hash:"",
        },
        {
            id:t('menu.service-2'),
            title:t('menu.service-2'),
            url:"/solutions",
            hash:"green",
        },
        {
            id:t('menu.service-3'),
            title:t('menu.service-3'),
            url:"/solutions",
            hash:"green",
        },
        {
            id:t('menu.service-4'),
            title:t('menu.service-4'),
            url:"/solutions",
            hash:"wali",
        },
        {
            id:t('menu.service-5'),
            title:t('menu.service-5'),
            url:"/solutions",
            hash:"wali",
        },
        {
            id:t('menu.service-6'),
            title:t('menu.service-6'),
            url:"/solutions",
            hash:"lightdonate",
        },        
    ];
    const dropItemSet = (isview) => {
        setDropclass(isview);
    };   
    const phoneClick = () => {
        setMenuVisible(!menuVisible);
    };
    const lanChange = () => {
        if(i18n.language === "zh-TW") {
            i18n.changeLanguage('EN');
            setCookie("lang","EN");
            if(props.reFreshWindow) props.reFreshWindow();            
        }else{
            i18n.changeLanguage('zh-TW');
            setCookie("lang","zh-TW");
            if(props.reFreshWindow) props.reFreshWindow(); 
        };        
    }
    const setCookie = (name,value) =>{
        // let days = date;//此 cookie 將被儲存的天數
        // let exp  = new Date();//建立一個時間日期物件;
        // exp.setTime(exp.getTime() + days*24*60*60*1000);
        document.cookie = name + "="+ value;
    }

    const scrollFunc = () => {      
        setScroll(window.scrollY > 0 ? "scroll" : "");
    }

    const menu = [
        <ul className="header-menu" key={"menu"}>
            <li>
                <NavLink to="/">
                    {t('menu.index')}
                </NavLink>
            </li>            
            <li>            
                <Dropdown view={dropItemSet} content={menuDropItem_about} title={t('menu.about')}/>
            </li>
            <li>
                <NavLink to="/news">
                    {t('menu.news')}
                </NavLink>
            </li>
            <li>
                <Dropdown view={dropItemSet} content={menuDropItem_solutions} title={t('menu.service')}/>
            </li>
            <li>
                <NavLink to="/project">
                    {t('menu.project')}
                </NavLink>
            </li>
            <li>
                <NavLink to="/contact">
                    {t('menu.contact')}
                </NavLink>
            </li>
            <li translate='no'>
                <div className="header-rightline"></div>
                <Button type="link" onClick={lanChange}>
                    {t('menu.language')}
                </Button>                
            </li>
        </ul>
    ];

    useEffect(()=>{
        if(props.transparent === "true"){
            window.addEventListener('scroll',scrollFunc);
        }
        
        return ()=> {
            if(props.transparent === "true"){
                window.removeEventListener('scroll',scrollFunc);
            }            
        }
    },[props.transparent]);

    useLayoutEffect(()=>{
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <header id="header" className={headerClassName + " " +  scroll + " " + dropclass} ref={ref}>
                <div className='header-bg'></div>
                <div className="header-container">
                    <Row justify="space-between">
                        <Col lg={8} md={6} sm={20} xs={20}>
                            <div className="header-logo">
                                <Link to="/" id="logo">
                                    <div className='logo-wrap'>
                                        <img src={logo} alt="logo"  className='logo'/>
                                    </div>
                                    <div className='logo-w-wrap'>
                                        <img src={logo_w} alt="logo" className='logo-w'/> 
                                    </div>                                                
                                </Link>
                            </div>                        
                        </Col>
                        <Col lg={16} md={18} sm={4} xs={4}>
                            <div
                                className={menuVisible ?'header-mobile-menu open':'header-mobile-menu'}
                                onClick={() => {
                                    phoneClick();
                                }}
                            >
                                <em />
                                <em />
                            </div>
                            { menu }
                        </Col>                    
                    </Row>
                    <div className="header-menuline"></div>   
                    <div className="header-bottomline"></div>                
                </div>   
            </header>
        </>
    );

})
export default Header;