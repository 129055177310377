import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Modal, Divider, Tabs, Collapse } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import logo from "../images/logo_white.svg";
import icon_arrow from "../images/icon-arrow.svg";
    
const year = new Date().getFullYear();    

export default function Footer(props){
    const { t } = useTranslation();
    const [data,setData] = useState(null);
    const [wali,setWali] = useState(null);
    const [dondi,setDondi] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { Panel } = Collapse;   

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    useEffect(()=>{
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API + 'electric-infos?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事         
            setData(response.data.data);         
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        });

        axios({
            method: 'GET',
            url: process.env.REACT_APP_API + 'electric-info-walis?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事 
            setWali(response.data.data);         
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        });

        axios({
            method: 'GET',
            url: process.env.REACT_APP_API + 'electric-info-dondis?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事 
            setDondi(response.data.data);         
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        });


    },[])

    return (
        <footer className="footer">
            <div className="container">
                <Row>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Link to="/" className='footer-logo'><img src={logo} alt="logo" /> </Link>
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>                         
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                         <Row gutter={[16, 16]}>
                            <Col lg={8} md={8} sm={8} xs={24}>
                                <h6><Link to="/about">{t('menu.about')}</Link></h6>
                                <Link className='footer-link' to="/about"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.about-1')}</Link>
                                <Link className='footer-link' to="/about"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.about-2')}</Link>
                                <Link className='footer-link' to="/about"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.about-3')}</Link>
                                <Link className='footer-link' to="/about"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.about-4')}</Link>
                                <Link className='footer-link' to="/about"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.about-5')}</Link>

                            </Col>
                            <Col lg={8} md={8} sm={8} xs={24}>
                                <h6><Link to="/solutions">{t('menu.service')}</Link></h6>
                                <Link className='footer-link' to="/solutions"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.service-1')}</Link>
                                <Link className='footer-link' to="/solutions"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.service-2')}</Link>
                                <Link className='footer-link' to="/solutions"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.service-3')}</Link>
                                <Link className='footer-link' to="/solutions"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.service-4')}</Link>
                                <Link className='footer-link' to="/solutions"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.service-5')}</Link>
                                <Link className='footer-link' to="/solutions"><div className='footer-link-img'><img src={icon_arrow} alt="arrow"/></div>{t('menu.service-6')}</Link>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={24}>
                                <h6><Link to="/news">{t('menu.news')}</Link></h6>
                                <h6><Link to="/project">{t('menu.project')}</Link></h6>
                                <h6><a href='/#' onClick={(e)=>{ e.preventDefault();showModal()}}>{t('menu.e-info')}</a></h6>
                                <Modal title={t('menu.e-info')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                    <div>
                                    <Tabs defaultActiveKey="1">
                                        <Tabs.TabPane tab="天璣智慧能源" key="1">
                                            <Collapse>                                                                        
                                                <Panel header="110年" key="1-1">
                                                    {data && data.map(item=>{
                                                        if(item.attributes.year === '110'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>                                                
                                                <Panel header="111年" key="1-2">
                                                    {data && data.map(item=>{
                                                        if(item.attributes.year === '111'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                                <Panel header="112年" key="1-3">
                                                    {data && data.map(item=>{
                                                        if(item.attributes.year === '112'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                                <Panel header="113年" key="1-4">
                                                    {data && data.map(item=>{
                                                        if(item.attributes.year === '113'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                            </Collapse>                                      
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="瓦力能源" key="2">
                                            <Collapse>
                                                <Panel header="111年" key="2-1">
                                                    {wali && wali.map(item=>{
                                                        if(item.attributes.year === '111'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                                <Panel header="112年" key="2-2">
                                                    {wali && wali.map(item=>{
                                                        if(item.attributes.year === '112'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                                <Panel header="113年" key="2-3">
                                                    {wali && wali.map(item=>{
                                                        if(item.attributes.year === '113'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                            </Collapse>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="東迪能源" key="3">
                                            <Collapse>
                                                <Panel header="111年" key="3-1">
                                                    {dondi && dondi.map(item=>{
                                                        if(item.attributes.year === '111'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                                <Panel header="112年" key="3-2">
                                                    {dondi && dondi.map(item=>{
                                                        if(item.attributes.year === '112'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                                <Panel header="113年" key="3-3">
                                                    {dondi && dondi.map(item=>{
                                                        if(item.attributes.year === '113'){
                                                            return <div className='datalist' key={item.attributes.filename}><DownloadOutlined /> <a target="_blank" rel="noopener noreferrer" href={`https://strapi.rfdme.io/uploads/${item.attributes.filename}`}>{item.attributes.title}</a></div>
                                                        }else{
                                                            return '';
                                                        }                                                    
                                                    })}
                                                </Panel>
                                            </Collapse>
                                        </Tabs.TabPane>
                                    </Tabs>
                                        
                                    </div>
                                </Modal>
                            </Col>
                        </Row> 
                    </Col>
                </Row>
                <Divider/>
                <div className='footer-copyright'>
                    <p>© {year} Micro Electricity Co., Ltd. All rights reserved.</p>
                </div>
            </div>            
        </footer>
    )
}