import React, { useEffect, useRef, useState } from 'react';
import { Link as Alink } from 'react-router-dom';
import Header from "../component/Header";
import { Row, Col, BackTop, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, MailOutlined } from '@ant-design/icons';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import service_kv from "../images/service_kv.jpg";
import service_img1 from "../images/service_img1.jpg";
import service_img2 from "../images/service_img2.jpg";
import service_img3 from "../images/service_img3.jpg";
import solar_img from "../images/solar-img.jpg";

export default function Service(props) {    
    const { t } = useTranslation();
    const [bannerOn, setBannerOn] = useState("");
    const [bannerShow, setBannerShower] = useState("");
    const showBanner = () =>{
        if(bannerShow === ""){
            setBannerShower("show");
        }else{
            setBannerShower("");
        }        
    }
    const hideBanner = () =>{
        setBannerShower("");
    }
    const toLink = (url) =>{
        let anchorElement = document.getElementById(url);
        anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
    const scrollFunc = ()=>{      
        setBannerOn(window.scrollY > 800 ? "on" : "");
    }
    const toHashHandler = (url) =>{
        if(url !== ""){            
            setTimeout(()=>{
                let anchorElement = document.getElementById(url);
                if(anchorElement){
                   anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});  
                }                
            },500);            
        }
    }
    const scrollref = useRef(null);

    useEffect(()=>{        
        gsap.registerPlugin(ScrollTrigger);
        // const element = scrollref.current;
        window.addEventListener('scroll',scrollFunc);

        return () => {
            window.removeEventListener('scroll',scrollFunc);
        }
    },[])

    return (
        <div ref={scrollref}>
            <Header/>
            <div className='page-title'>
                {t('service.subtitle') ? <p className='page-title-sub'>{t('service.subtitle')}</p> : ''}
                <h1>SOLUTIONS</h1>                
                <ul className='page-title-link'>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("solar")}} >{t('menu.service-1')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("green")}} >{t('menu.service-2')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("green")}} >{t('menu.service-3')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("wali")}} >{t('menu.service-4')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("wali")}} >{t('menu.service-5')}</a></li>
                    <li><a href='/' onClick={(e)=> {e.preventDefault(); toLink("lightdonate")}} >{t('menu.service-6')}</a></li>                    
                </ul>                
            </div>           
            <div className='page-kv'>
                <div className='page-kv-img-wrap'>
                    <img src={service_kv} alt={service_kv} className='page-kv-img'/>
                </div>                
            </div>
            <div className='breadcrumb'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Alink to="/">Home</Alink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Solutions
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>   
            </div>
            <section className='page-service-section solar-section' id="solar">
                <div className='container'>
                    <Row gutter={[32, 0]}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <div className=''>
                                <h2>{t('service.solar-title')}</h2>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className='solar-txt'>
                                <h3>{t('service.solar-subtitle1')}</h3>
                                <p>{t('service.solar-p1')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.solar-tag1-1')}</li>
                                    <li>{t('service.solar-tag1-2')}</li>
                                    <li>{t('service.solar-tag1-3')}</li>
                                    <li>{t('service.solar-tag1-4')}</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className='solar-txt'>
                                <h3>{t('service.solar-subtitle2')}</h3>
                                <p>{t('service.solar-p2')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.solar-tag2-1')}</li>
                                    <li>{t('service.solar-tag2-2')}</li>
                                </ul>
                            </div>   
                            <div className='solar-txt'>
                                <h3>{t('service.solar-subtitle3')}</h3>
                                <p>{t('service.solar-p3')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.solar-tag3-1')}</li>
                                    <li>{t('service.solar-tag3-2')}</li>
                                </ul>
                            </div> 
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className='solar-img'>
                                <img src={solar_img} alt={solar_img} />
                            </div> 
                        </Col>       
                    </Row>
                </div>
            </section>
            <section className='page-service-section green-section' id="green">
                <div className='section-top-line'></div>
                <div className='container'>
                    <Row justify="space-between" align="middle">
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className='green-txt'>
                                <h2>{t('service.solve-title')}</h2>
                                <h3>{t('service.solve-subtitle')}</h3>
                                <p>{t('service.solve-p')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.solve-tag-1')}</li>
                                    <li>{t('service.solve-tag-2')}</li>
                                </ul>
                            </div>   
                            <div className='green-txt'>
                                <h2>{t('service.e-sale-title')}</h2>                                
                                <p>{t('service.e-sale-p')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.e-sale-tag-1')}</li>
                                    <li>{t('service.e-sale-tag-2')}</li>
                                </ul>
                            </div>                         
                        </Col>
                        <Col lg={11} md={11} sm={24} xs={24}>
                            <div className='green-img'>
                                <img src={service_img1} alt={service_img1} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='page-service-section wali-section' id="wali">
                <div className='section-top-line'></div>
                <div className='container'>
                    <Row justify="space-between" align="middle">
                        <Col lg={11} md={11} sm={24} xs={24}>
                            <div className='wali-img'>
                                <img src={service_img2} alt={service_img2} />
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className='green-txt'>
                                <h2>{t('service.green-title')}</h2>
                                <p>{t('service.green-p')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.green-tag-1')}</li>
                                    <li>{t('service.green-tag-2')}</li>
                                    <li>{t('service.green-tag-3')}</li>
                                    <li>{t('service.green-tag-4')}</li>
                                </ul>
                            </div> 
                            <div className='green-txt'>
                                <h2>{t('service.greensale-title')}</h2>                                
                                <p>{t('service.greensale-p')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.greensale-tag-1')}</li>
                                    <li>{t('service.greensale-tag-2')}</li>
                                </ul>
                            </div>                                                       
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='page-service-section lightdonate-section' id="lightdonate">
                <div className='section-top-line'></div>
                <div className='container'>
                    <Row gutter={[24,0]}>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <h2>{t('service.lightdonate-title')}</h2>  
                        </Col>
                        <Col lg={16} md={16} sm={24} xs={24}>
                            <div className='lightdonate-txt'>                             
                                <p>{t('service.lightdonate-p')}</p>
                                <ul className='service-hash-ul'>
                                    <li>{t('service.lightdonate-tag-1')}</li>
                                    <li>{t('service.lightdonate-tag-2')}</li>
                                    <li>{t('service.lightdonate-tag-3')}</li>
                                </ul>
                            </div>                            
                        </Col>
                    </Row>
                    {/* <div className='lightdonate-margin'></div> */}
                </div>
                <div className='service_img_wrap'>
                    <img className='service_img' src={service_img3} alt={service_img3} />
                </div>                
            </section>
            <div className={`contact-banner ${bannerOn} ${bannerShow}`}>
                <div className='contact-banner-header' onClick={showBanner}>
                    <div className='contact-banner-header-title'>
                        <MailOutlined />
                        <h3>{t('service.contact-title')}</h3>
                    </div>                    
                    <div className='contact-banner-header-closed' onClick={hideBanner}>
                        <CloseOutlined />
                    </div>                    
                </div>
                <div className='contact-banner-body'>
                    <p>{t('service.contact-p')}</p>
                    <Alink className='contact-banner-btn' to="/contact" onClick={()=> toHashHandler("contactform")}>{t('service.contact-btn')}</Alink>
                </div>
            </div>
            <BackTop />
        </div>
        
    );
}