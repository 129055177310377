import React, { useEffect, useState } from 'react';
import { Link, useParams, Navigate } from 'react-router-dom';
import Header from "../component/Header";
import { Row, Col, BackTop, Breadcrumb, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import kvimg from "../images/news_bg.jpg";

export default function NewsPage() {    
    const { t, i18n } = useTranslation();
    const id = useParams();
    const [fetchData,setFetchData] = useState(null);
    function filterData() {
        const currentData = fetchData.filter(item => {
            return parseInt(item.id) === parseInt(id.id);
        });
        const [currentItem] = currentData;
        return currentItem;
    }

    const renderData = ()=>{
        const currentItem = filterData();
        if(currentItem === undefined) return <Navigate to="/404" />
        const content = currentItem.attributes.content.replace(/<img src="\/uploads\//gi, '<img src="https://strapi.rfdme.io/uploads/');
        return <Row gutter={[64, 48]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                    <div className='news-page-box-title'>                                   
                        <h1>{currentItem.attributes.title}</h1>                                    
                        <div className="publish">{currentItem.attributes.release_unit}</div>
                        <div className="date">{currentItem.attributes.dt.split("-")[0] + '.' + currentItem.attributes.dt.split("-")[1] + '.' + currentItem.attributes.dt.split("-")[2].slice(0,2)}</div>
                    </div>
                </Col>
                <Col lg={16} md={16} sm={24} xs={24}>
                    <div className="news-page-box-content">
                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                </Col>
            </Row>        
    }
    const idArray = ()=>{
        const arr =[]
        const filterArr = fetchData.filter(item =>{
            return item.external !== "Y";
        })
        filterArr.forEach((item,index)=>{
            arr.push(item.id);
        });
        return arr;        
    }
    const prevPage = (id)=>{
        const arr = idArray();        
        const index = arr.indexOf(Number(id.id));
        if(index <= 0 ){
            return arr[arr.length-1];
        }else{
            return arr[index-1];
        }
    }  
    const nextPage = (id)=>{
        const arr = idArray();
        const index = arr.indexOf(Number(id.id));
        if(index >= arr.length-1 ){
            return arr[0];
        }else{
            return arr[index+1];
        }
    }
    const hotNews = () =>{
        const filterNews = fetchData.filter(item =>{
            return parseInt(item.id) !== parseInt(id.id);
        })
        return filterNews.slice(0,4);
    }
    //去除字串中的html標籤並只留下50字
    const delHtmlTag = (str) => {
        str = str.replace(/<figure\b[^<]*(?:(?!<\/figure>)<[^<]*)*<\/figure>/gi,"");
        str = str.replace(/<[^>]+>/g,"");
        if(str.length > 50){
            str = str.substring(0,50);
        }
        return str;
    }
    const loadItem = () => {   
        let arr = [1,2,3,4];
        return arr.map((item,index) => {
            return <div className='news-item' key={index + '1'}>
                        <Skeleton active avatar />
                    </div>
        });
        
    };
    useEffect(()=>{        
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API + 'newsposts?pagination[page]=1&pagination[pageSize]=999',
            responseType: 'json', 
            headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` 
            }
        }).then(function (response) { // 成功後要做的事  
            let data = response.data.data.sort(function(a,b){
                return Date.parse(b.attributes.dt) - Date.parse(a.attributes.dt);
            });
            if(i18n.resolvedLanguage !== "EN"){
                let newData = data.filter(item => item.attributes.en !== "Y");
                newData = data.filter(item => item.attributes.active !== false);
                setFetchData(newData); 
            }else{
                let newData = data.filter(item => item.attributes.active !== false);
                setFetchData(newData);
            }
        }).catch(function (error) { // 失敗後要做的事
            console.log(error);
        })
    },[i18n.resolvedLanguage,id.id]);

    if(fetchData) {
        return (
            <>
                <Header/>
                <div className='page-title-bg' style={{backgroundImage:`url(${kvimg})`}} id='pagetitle'>
                    {t('news.subtitle') ? <p className='page-title-sub'>{t('news.subtitle')}</p> : ''}  
                    <h1>NEWS</h1>                
                </div>  
                <div className='breadcrumb'>
                    <div className='container-s'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/">Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/news">News</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {fetchData ? filterData?.()?.attributes?.title : ''}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>   
                </div>
                <section className='page-news-page-section'>
                    <div className="container-s">
                        <div className="news-page-box">  
                            {fetchData ? renderData() : <Skeleton active avatar />} 
                        </div>
                    </div>
                </section>
                <div className="news-page-next">
                    <div className="container-s">
                        <Row justify="space-between" align="middle">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Link className='news-page-next-back' to='/news'>Back to List</Link>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="pre-next">
                                    <Link className="btn-more isview pre-btn" to={fetchData ? `/news/${prevPage(id)}`: ''}>
                                        <div className="btn-more-wrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                                            <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                                            <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                                            </svg>
                                            <i></i>
                                        </div>
                                    </Link>
                                    <Link className="btn-more isview next-btn"  to={fetchData ? `/news/${nextPage(id)}` : ''}>
                                        <div className="btn-more-wrap">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                                            <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                                            <circle r="38" cx="40" cy="40" strokeWidth="2" fill="none"></circle>
                                            </svg>
                                            <i></i>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="news-page-hot">
                    <div className="container-s">
                        <h3 className='news-page-hot-title'>HOT NEWS</h3>
                        <Row gutter={[48, 24]}>
                            {fetchData ? hotNews().map(item => {
                                return  <Col lg={12} md={12} sm={24} xs={24} key={item.id}>
                                            <Link className='news-item' to={`/news/${item.id}`} >                                            
                                                <div className='news-item-date'>
                                                    <div className='news-item-date-day'>{item.attributes.dt ? item.attributes.dt.split("-")[2].slice(0,2) : ''}</div>
                                                    <div className='news-item-date-month'>{item.attributes.dt ? item.attributes.dt.split("-")[0]+ "." + item.attributes.dt.split("-")[1] : ''}</div>
                                                </div>
                                                <div className='news-item-content'>
                                                    <h3>{item.attributes.title}</h3>
                                                    <p dangerouslySetInnerHTML={{__html:delHtmlTag(item.attributes.content)}}></p>
                                                </div>
                                            </Link>
                                        </Col>
                            }) : loadItem()}                     
    
                        </Row>
                    </div>
                </div>
                <BackTop />
            </>
            
        );
    }else{
        return null
    }
    
}